import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import InfoIcon from '@mui/icons-material/Info'
import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material'

import Adres from '@components/Adres'
import { DisciplineIcon } from '@components/DisciplineIcon'
import StyledIconLink from '@components/StyledIconLink'
import Uitvoerende from '@components/Uitvoerende'
import { formatDate } from '@util/date-util'

import StatusPopover from './Status'

const STATUS_OPLEVERING = 500

export const Row = ({ row, canNavigateToProject = false }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <TableRow key={row.opdrachtId}>
        <TableCell>{row.opdrachtId}</TableCell>
        <TableCell>{row.clusterId}</TableCell>
        <TableCell>{row.versieNummer}</TableCell>
        <TableCell>
          <DisciplineIcon
            disciplineCode={row.discipline}
            toolTip={row.discipline === 'M' ? 'Glas' : row.disciplineLabel}
          />
        </TableCell>
        <TableCell>{row.details && <Adres {...row.details} />}</TableCell>
        <TableCell>{row.netbeheerder}</TableCell>
        <TableCell>
          <Uitvoerende {...row} />
        </TableCell>
        <TableCell>
          {formatDate(row.details?.creatieDatum) || 'Onbekend'}
        </TableCell>
        <TableCell>{formatDate(row.changed)}</TableCell>
        <TableCell>
          {row.status === STATUS_OPLEVERING ? (
            <StatusPopover row={row} text={row.statusText} />
          ) : (
            <p>{row.statusText}</p>
          )}
        </TableCell>
        <TableCell>
          <StyledIconLink
            title={`Navigeer naar opdracht ${row.opdrachtId}`}
            icon={<LaunchIcon />}
            href={`/opdracht/${row.opdrachtId}`}
          />
          {canNavigateToProject && (
            <>
              <IconButton data-testid="buttonMenu" onClick={handleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                data-testid="menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
              >
                <Link
                  to={`/aansluiting/project/${row.clusterId}`}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project details" />
                  </MenuItem>
                </Link>
              </Menu>
            </>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
