import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Box, Grid, Typography } from '@mui/material'

import { ProjectAdres } from '@components/Adres'
import { DisciplineIcon } from '@components/DisciplineIcon'
import Label from '@components/Label'
import { formatDate } from '@util/date-util'
import { disciplines as disciplineMap } from '@util/discipline'

import { getAssignedBijlagenForEntity } from '../../../entity/bijlagen/state'
import type { HoofdleidingProject } from '../../projecten/types'

export interface Props {
  project: HoofdleidingProject
}

export const Gegevens: FC<Props> = ({ project }: Props) => {
  const bijlagen = useSelector(getAssignedBijlagenForEntity)

  let opleverweekWerkmap: string | undefined

  if (bijlagen.length) {
    const werkmap = bijlagen.find(
      (x) => x.documentsoort === 'Werkmap' && x.status !== 'cancelled',
    )

    if (werkmap) {
      opleverweekWerkmap = werkmap.dueWeek
    }
  }

  return (
    <Grid sx={{ p: 1, width: '95%', mx: 'auto' }} container spacing={2}>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Type</b>
        </Typography>
        <Typography variant="body2">{project.type}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Externe referentie</b>
        </Typography>
        <Typography variant="body2">{project.externeReferentie}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Naam</b>
        </Typography>
        <Typography variant="body2">{project.naam}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Processtap</b>
        </Typography>
        <Typography variant="body2">{project.statusText}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Disciplines</b>
        </Typography>
        <Typography variant="body2">{project.uniqDisciplines}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Adres</b>
        </Typography>
        <Typography variant="body2">
          {project && <ProjectAdres {...project} />}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Netbeheerders</b>
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {disciplineMap.map((disc) => {
            return (
              <DisciplineIcon
                key={disc}
                disciplineCode={disc}
                active={project.disciplineVerzamelingen.some(
                  (x) => x.disciplineCode === disc,
                )}
                toolTip={project.disciplineVerzamelingen
                  .filter((x) => x.disciplineCode === disc)
                  .map((x) => x.netbeheerderLabel)
                  .join(', ')}
              />
            )
          })}
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Uitvoerende aannemers</b>
        </Typography>
        <Typography variant="body2" component={'span'}>
          {project.aannemers && project.aannemers.join(',')}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Verantwoordelijken</b>
        </Typography>
        <Typography variant="body2">
          {project.verantwoordelijken.join(',')}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Opleverweek werkmap</b>
        </Typography>
        <Typography variant="body2">{opleverweekWerkmap}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Start uitvoering</b>
        </Typography>
        <Typography variant="body2">{project.startUitvoeringWeek}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Doorlooptijd (in weken)</b>
        </Typography>
        <Typography variant="body2">{project.doorlooptijdWeken}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Gemeente</b>
        </Typography>
        <Typography variant="body2">{project.gemeente}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Type werk</b>
        </Typography>
        <Typography variant="body2">{project.typeWerk}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Combi</b>
        </Typography>
        <Typography variant="body2">{project.combi}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Gebied</b>
        </Typography>
        <Typography variant="body2">{project.gebiedscodeLabel}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Aanvraagdatum</b>
        </Typography>
        <Typography variant="body2">
          {formatDate(project.aanvraagDatum)}
        </Typography>
      </Grid>

      {project.labels && project.labels.length > 0 && (
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <b>Labels</b>
          </Typography>
          {project.labels.map((label) => (
            <Label key={label} text={label} />
          ))}
        </Grid>
      )}
      {typeof project.tracelengte === 'number' && (
        <Grid item xs={3}>
          <Typography gutterBottom variant="subtitle1">
            <b>Tracélengte (meters)</b>
          </Typography>
          <Typography variant="body2">{project.tracelengte}</Typography>
        </Grid>
      )}
      {typeof project.woningen === 'number' && (
        <Grid item xs={3}>
          <Typography gutterBottom variant="subtitle1">
            <b>Aantal (grondgebonden) woningen</b>
          </Typography>
          <Typography variant="body2">{project.woningen}</Typography>
        </Grid>
      )}

      {typeof project.appartementen === 'number' && (
        <Grid item xs={3}>
          <Typography gutterBottom variant="subtitle1">
            <b>Aantal appartementen</b>
          </Typography>
          <Typography variant="body2">{project.appartementen}</Typography>
        </Grid>
      )}
    </Grid>
  )
}
