import React, { type FC } from 'react'

import { DisciplineIcon } from './DisciplineIcon'

export { ProductIcon }

type Props = {
  product: {
    disciplineCode: string
    disciplineLabel: string
    netbeheerderCode: string
    isGrootverbruik?: boolean
  }
  onClick?: any
  badge?: any
}

const ProductIcon: FC<Props> = ({
  product: {
    disciplineCode,
    disciplineLabel,
    netbeheerderCode,
    isGrootverbruik,
  },
  onClick,
  badge,
}) => (
  <DisciplineIcon
    onClick={onClick}
    disciplineCode={disciplineCode}
    toolTip={disciplineLabel}
    badge={badge}
    active={Boolean(netbeheerderCode)}
    isGrootverbruik={isGrootverbruik}
  />
)
