import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import { cancelActiviteitForEntity } from './../../state'

interface Props {
  open: any
  data: any
  handleClose: any
}

export const CancelActiviteitDialog: FC<Props> = ({
  open,
  data,
  handleClose,
}) => {
  const dispatch = useDispatch()
  const { activiteit } = data
  const { _id, type, entity } = activiteit
  const [comment, setComment] = useState('')

  useEffect(() => {
    setComment('')
  }, [open])

  const handleClickJa = () => {
    dispatch(
      cancelActiviteitForEntity({
        entity,
        activiteitInfo: {
          activiteitId: _id,
          entity,
          text: comment,
          status: 'cancelled',
        },
      }),
    )
  }

  return (
    <>
      <DialogTitle>{`Annuleren ${type}`}</DialogTitle>
      <DialogContent>
        <Typography>{`Wilt u deze ${type} annuleren? `}</Typography>
        <TextField
          id="standard-textarea"
          fullWidth
          label={`Eventuele extra opmerking (${comment.length}/500)`}
          placeholder="Opmerking"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          sx={{ mt: 1 }}
          rows={5}
          multiline
          inputProps={{ maxLength: 500 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Nee
        </Button>
        <Button onClick={handleClickJa} color="primary">
          Ja
        </Button>
      </DialogActions>
    </>
  )
}
