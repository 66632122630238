import React, { FC, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material'

import { ProductIcon } from '@components/ProductIcon'
import StyledDetailsTable from '@components/StyledDetailsTable'
import { formatDate } from '@util/date-util'
import { createBadge } from '@util/kpi-util'

import type { AansluitObject, Produkt, Project } from '../../project/types'

export interface Props {
  project: Project
  object: AansluitObject
  product: Produkt
  expanded: boolean
}

export const Discipline: FC<Props> = ({
  project,
  object,
  product,
  expanded,
}) => {
  const [expand, setExpand] = useState(expanded)
  const valid = project && object && product
  const {
    discipline,
    disciplineLabel,
    aannemer,
    opdrachtnemer,
    opdrachtIDs,
    disciplineID,
    specificatie,
    productCode,
    dienstCodeLabel,
    subdienstCodeLabel,
    agd,
  } = product
  const onClickExpand = () => setExpand(!expand)
  const badge = createBadge(product.kpi)
  const showMessage = badge && badge.msg
  const messageColor =
    badge && badge.color === 'red' ? badge.color : 'darkorange'
  const gpsCoordinaten = `${object.coordinaten?.breedtegraad},${object.coordinaten?.lengtegraad}`

  return (
    <>
      {valid && (
        <TableRow key={`${object.objectID}${product.disciplineCode}`}>
          <TableCell>
            <ProductIcon product={product} />
          </TableCell>
          <TableCell>
            <Typography>{object.adres}</Typography>
          </TableCell>
          <TableCell>
            <IconButton onClick={onClickExpand}>
              {expand ? <CloseIcon /> : <AddIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {valid && expand && (
        <TableRow key={`${object.objectID}${product.disciplineCode}-details`}>
          <TableCell colSpan={3}>
            {showMessage && (
              <Box
                sx={{
                  width: '85%',
                  m: 1,
                  mt: '20px',
                  padding: '20px',
                  verticalAlign: 'center',
                  fontSize: '1.3rem',
                  backgroundColor: messageColor,
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                {badge.msg}
              </Box>
            )}
            <StyledDetailsTable>
              <TableRow>
                <TableCell>Project ID:</TableCell>
                <TableCell>{project.aanvraagID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Type:</TableCell>
                <TableCell>{project.type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {project.agdRelevant ? 'Aansluitgereed klant:' : 'Wensdatum:'}
                </TableCell>
                <TableCell>
                  {project.agdRelevant
                    ? formatDate(agd) || 'Onbekend'
                    : formatDate(project.wensdatum)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Processtap:</TableCell>
                <TableCell>{product.statusText}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gebied:</TableCell>
                <TableCell>{project.gebiedscodeLabel}</TableCell>
              </TableRow>
              {object.coordinaten && (
                <TableRow>
                  <TableCell>GPS-Coördinaten:</TableCell>
                  <TableCell>
                    <a
                      href={`https://www.google.nl/maps/place/${gpsCoordinaten}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {gpsCoordinaten}
                    </a>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Object ID:</TableCell>
                <TableCell>{object.objectID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Netbeheerder:</TableCell>
                <TableCell>{product.netbeheerderLabel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Uitvoerende:</TableCell>
                <TableCell>{aannemer || 'Nog niet bekend'}</TableCell>
              </TableRow>
              {opdrachtnemer && (
                <TableRow>
                  <TableCell>Opdrachtnemer:</TableCell>
                  <TableCell>{opdrachtnemer}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>DisciplineID:</TableCell>
                <TableCell>{disciplineID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discipline:</TableCell>
                <TableCell>{discipline || disciplineLabel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>BAG Object Code:</TableCell>
                <TableCell>{object.bagObjectCode || ''}</TableCell>
              </TableRow>
              {project.type === 'Aanvraag' && (
                <>
                  <TableRow>
                    <TableCell>Dienstcode:</TableCell>
                    <TableCell>{dienstCodeLabel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Subdienstcode:</TableCell>
                    <TableCell>{subdienstCodeLabel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Productcode:</TableCell>
                    <TableCell>{productCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Specificatie:</TableCell>
                    <TableCell>{specificatie}</TableCell>
                  </TableRow>
                </>
              )}
              {opdrachtIDs &&
                opdrachtIDs.map((opdrachtID, index) => (
                  <TableRow key={index}>
                    <TableCell>{index === 0 ? 'OpdrachtID:' : ''}</TableCell>
                    <TableCell>{opdrachtID}</TableCell>
                  </TableRow>
                ))}
            </StyledDetailsTable>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
