import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import HistoryIcon from '@mui/icons-material/History'
import InfoIcon from '@mui/icons-material/Info'
import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Avatar,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'

import Adres from '@components/Adres'
import { DisciplineIcon } from '@components/DisciplineIcon'
import { MyListButton } from '@components/MyListButton'
import StyledIconLink from '@components/StyledIconLink'
import Uitvoerende from '@components/Uitvoerende'
import { formatDate } from '@util/date-util'
import { createBadge } from '@util/kpi-util'

import type { Opdracht } from '../../../opdracht/types'
import StatusPopover from '../../components/Status'

import { Kpi } from './Kpi'

const fromDetails = (row, prop) => (row && row.details ? row.details[prop] : '')
const fromCombi = (row, prop) =>
  row && row.details && row.details.combi ? row.details.combi[prop] || '' : ''

function DirtyIcon() {
  return (
    <span style={{ paddingLeft: 7 }}>
      <Tooltip title="Door wijzigingen worden KPI's herberekend">
        <HistoryIcon />
      </Tooltip>
    </span>
  )
}

function StatusIcon({ row }) {
  const kpi = fromCombi(row, 'kpi')
  const badge = kpi && createBadge(kpi)

  if (fromCombi(row, 'isDirty')) return <DirtyIcon />

  return badge ? <Kpi kpi={kpi} /> : null
}
const STATUS_OPLEVERING = 500

interface Props {
  row: Opdracht
  showProjectOptions?: boolean
}

export const Row: FC<Props> = ({ row, showProjectOptions = false }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <TableRow key={row.opdrachtId}>
        <TableCell>
          <StatusIcon row={row} />
        </TableCell>
        <TableCell>{row.opdrachtId}</TableCell>
        <TableCell>
          {fromDetails(row, 'clusterCodeNB') ? (
            <Tooltip
              title={fromDetails(row, 'clusterCodeNB')}
              placement="right"
            >
              <span>{row.clusterId}</span>
            </Tooltip>
          ) : (
            row.clusterId
          )}
        </TableCell>
        <TableCell>
          <Chip
            variant="outlined"
            avatar={
              <Tooltip
                title={fromCombi(row, 'realisatietype')}
                placement="right"
              >
                <Avatar
                  sx={{
                    color: 'black !important',
                    cursor: 'default',
                    backgroundColor: '#eeeeee',
                  }}
                >
                  {fromCombi(row, 'realisatietype').substring(0, 1)}
                </Avatar>
              </Tooltip>
            }
            label={formatDate(fromCombi(row, 'realisatiedatum'))}
          />
        </TableCell>
        <TableCell>
          <DisciplineIcon
            disciplineCode={row.discipline}
            toolTip={row.disciplineLabel}
          />
        </TableCell>
        <TableCell>{row.details && <Adres {...row.details} />}</TableCell>
        <TableCell>{row.netbeheerder}</TableCell>
        <TableCell>
          <Uitvoerende {...row} />{' '}
        </TableCell>
        <TableCell>{fromCombi(row, 'gebiedscodeLabel')}</TableCell>
        <TableCell>
          {row.status === STATUS_OPLEVERING ? (
            <StatusPopover row={row} text={row.statusText} />
          ) : (
            <p>{row.statusText}</p>
          )}
        </TableCell>
        <TableCell>{formatDate(row.changed)}</TableCell>
        <TableCell>
          <MyListButton
            type="hoofdleiding"
            disabled={true}
            active={row.myList}
            aanvraagID={''}
          />

          <StyledIconLink
            title={`Navigeer naar opdracht ${row.opdrachtId}`}
            icon={<LaunchIcon />}
            href={`/combiopdracht/${row.opdrachtId}`}
          />
          <IconButton data-testid="buttonMenu" onClick={handleMenu}>
            <MoreVertIcon />
          </IconButton>
          {showProjectOptions && ( // this check is outside as the project details is now the only button there
            <Menu
              id="menu-appbar"
              data-testid="menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              onClose={handleClose}
            >
              <Link
                to={`/aansluiting/project/${row.clusterId}`}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Project details" />
                </MenuItem>
              </Link>
            </Menu>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
