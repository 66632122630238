import React, { type FC } from 'react'

import { Badge, Box, Tooltip } from '@mui/material'

export { DisciplineIcon }

const backgroundDict = {
  /** Electriciteit */
  E: {
    backgroundColor: 'rgb(79, 186, 219)',
    backgroundImage: 'url(/images/elektriciteit-icon-white.svg)',
  },
  /** Gas */
  G: {
    backgroundColor: 'rgb(188, 53, 141)',
    backgroundImage: 'url(/images/gas-icon-white.svg)',
  },
  /** Water */
  W: {
    backgroundColor: 'rgb(61, 108, 180)',
    backgroundImage: 'url(/images/water-icon-white.svg)',
  },
  /** Media */
  M: {
    backgroundColor: 'rgb(40, 162, 124)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  /** CAI */
  C: {
    backgroundColor: 'rgb(231, 0, 0)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  /** Koper */
  K: {
    backgroundColor: 'rgb(228, 193, 24)',
    backgroundImage: 'url(/images/media-icon-white.svg)',
  },
  /** Heat (warmte, W was bezet voor water) */
  H: {
    backgroundColor: 'rgb(221, 128, 37)',
    backgroundImage: 'url(/images/heat-icon-white.svg)',
  },
  /** Riool */
  R: {
    backgroundColor: 'rgb(124, 91, 174)',
    backgroundImage: 'url(/images/riool-icon-white.svg)',
  },
} as const

type Props = {
  disciplineCode: string
  toolTip: string
  active?: boolean
  badge?: {
    color: string
    count: number
    msg?: string
  }
  isGrootverbruik?: boolean
  onClick?: any
}

const DisciplineIcon: FC<Props> = ({
  disciplineCode,
  toolTip,
  active = true,
  badge = {},
  isGrootverbruik = false,
  onClick,
}) => {
  const disabled = !active
  const clickable = onClick && active
  const badgeColor = badge && badge.color === 'red' ? badge.color : 'darkorange'
  let title = badge.msg ? badge.msg : toolTip

  if (isGrootverbruik) {
    title = title + ' (grootverbruik)'
  }

  const image = backgroundDict[disciplineCode]?.backgroundImage || 'gray'
  const backgroundColor =
    backgroundDict[disciplineCode]?.backgroundColor || 'gray'

  return (
    <Box sx={{ position: 'relative', width: 40, height: 40 }}>
      <Tooltip title={title}>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: badgeColor,
              color: 'white',
            },
          }}
          badgeContent={badge.count}
          overlap="circular"
          onClick={clickable ? onClick : undefined}
        >
          <Box
            sx={{
              width: 30,
              height: 30,
              margin: '5px',
              borderRadius: '100%',
              backgroundPosition: 'center 5px',
              backgroundSize: 20,
              backgroundRepeat: 'no-repeat',
              backgroundImage: image,
              backgroundColor: backgroundColor,
              cursor: clickable ? 'pointer' : 'help',
              ...(disabled && {
                filter: 'grayscale(100%)',
                opacity: 0.3,
              }),
            }}
          />
        </Badge>
      </Tooltip>

      {isGrootverbruik && (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            bottom: -4,
            right: -4,
            width: 20,
            height: 20,
            borderRadius: '100%',
            background: 'white',
            border: `1px solid ${backgroundColor}`,
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: backgroundColor,
          }}
        >
          G
        </Box>
      )}
    </Box>
  )
}
