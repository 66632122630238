import React from 'react'

import ContactMailIcon from '@mui/icons-material/ContactMail'
import FolderIcon from '@mui/icons-material/Folder'
import PolylineIcon from '@mui/icons-material/Polyline'
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite'
import { Badge, Tooltip, Typography } from '@mui/material'

import {
  compareISOWeeks,
  formatDate,
  formatDateToISOWeek,
} from '@util/date-util'

import type { AssignedBijlage } from '../../../entity/bijlagen/types'

const icon = {
  m: '1px',
  borderRadius: '100%',
  backgroundPosition: 'center 5px',
  backgroundSize: '20px',
  backgroundRepeat: 'no-repeat',
  cursor: 'help',
  padding: '5px',
}

const badgeStyle = (color) => ({
  '& .MuiBadge-badge': { background: color, color: 'white' },
})

const BIJLAGEN = ['Schetsontwerp', 'CROW 400 bodem', 'Vergunning', 'Werkmap']
const bijlageToIconMap = {
  Werkmap: <FolderIcon sx={icon} />,
  Plantekening: <PolylineIcon sx={icon} />,
  'CROW 400 bodem': <SettingsInputCompositeIcon sx={icon} />,
  Schetsontwerp: <PolylineIcon sx={icon} />,
  Vergunning: <ContactMailIcon sx={icon} />,
}

const currentISOWeek = formatDateToISOWeek(new Date())

interface Props {
  bijlagen?: AssignedBijlage[]
  startUitvoeringWeek?: string
}

export const Mijlpalen = ({ bijlagen = [], startUitvoeringWeek }: Props) => {
  const getBadgeNumber = (documentsoort: string) =>
    bijlagen
      .filter((x) => x.documentsoort === documentsoort)
      .filter((x) => !x.completed).length || '0'

  const getBadgeColor = (documentsoort: string) => {
    const filteredBijlagen = bijlagen.filter(
      (x) => x.documentsoort === documentsoort && x.status !== 'cancelled',
    )

    // if all have been completed
    if (
      filteredBijlagen.length &&
      filteredBijlagen.every((x) => x.status === 'done')
    )
      return 'green'

    // if no startUitvoeringWeek there is no dueWeek on bijlagen
    if (!startUitvoeringWeek || filteredBijlagen.every((x) => !x.dueWeek))
      return 'grey'

    // if any bijlagen are due in 1 week
    if (
      filteredBijlagen.some(
        (x) => x.dueWeek && compareISOWeeks(currentISOWeek, x.dueWeek) === 1,
      )
    )
      return 'orange'

    // if any bijlagen have expired
    if (
      filteredBijlagen.some(
        (x) => x.dueWeek && compareISOWeeks(currentISOWeek, x.dueWeek) < 1,
      )
    )
      return 'red'

    // if more than 1 week in future, lightgrey
    if (
      filteredBijlagen.some(
        (x) => x.dueWeek && compareISOWeeks(currentISOWeek, x.dueWeek) > 1,
      )
    )
      return 'grey'
  }

  const tooltipBuilder = (documentsoort: string) =>
    bijlagen
      .filter((x) =>
        ['uploaded', 'assigned', 'review', 'done'].includes(x.status),
      )
      .filter((x) => x.documentsoort === documentsoort)
      .map((x) => (
        <Typography variant="body2" key={`${x.assignedTo}-${x.discipline}`}>
          {x.assignedTo} {x.discipline && `(${x.discipline})`} -{' '}
          {x.status === 'done'
            ? `Opgeleverd op ${formatDate(x.completed?.date)}`
            : `${x.dueWeek || 'Geen opleverweek'}`}
        </Typography>
      ))

  return (
    <>
      {BIJLAGEN.map((b) => (
        <Tooltip
          key={b}
          title={
            <>
              <Typography>{b}</Typography>
              {tooltipBuilder(b)}
            </>
          }
        >
          <Badge
            badgeContent={getBadgeNumber(b)}
            overlap="circular"
            sx={{
              ...badgeStyle(getBadgeColor(b)),
            }}
          >
            {bijlageToIconMap[b]}
          </Badge>
        </Tooltip>
      ))}
    </>
  )
}
