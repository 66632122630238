import React, { FC, Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import { DisciplineIcon } from '@components/DisciplineIcon'
import Label from '@components/Label'
import type { Event } from '@models/Event'
import type { Gebied } from '@models/Gebied'
import { getValuelists } from '@slices/valuelistsSlice'
import { formatDate } from '@util/date-util'
import { getEventConfig, questionIdMap } from '@util/event-util'
import { getProjectLabel, updatableProps } from '@util/project-util'

interface Props {
  event: Event
  open: boolean
}

const makeCreateGebiedscodeLabel =
  (gebieden: Gebied[]) => (gebiedscode: string) => {
    const gebied = gebieden.find((g) => g.code === gebiedscode)
    return gebied ? `${gebiedscode} - ${gebied.label}` : gebiedscode
  }

export const EventCard: FC<Props> = ({ event, open }) => {
  const [localOpen, setLocalOpen] = useState(open)
  const { gebieden } = useSelector(getValuelists)
  const gebiedscodeLabel = makeCreateGebiedscodeLabel(gebieden)

  const { label, Icon, background, expandable, caption } = getEventConfig(event)

  useEffect(() => {
    setLocalOpen(open)
  }, [open])

  return (
    <Card key={event._id} variant="outlined" sx={{ mt: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: 'body1',
        }}
        title={label}
        subheaderTypographyProps={{
          variant: 'body2',
        }}
        subheader={caption}
        avatar={
          <Avatar sx={{ color: '#333333', background }} alt={label}>
            <Icon />
          </Avatar>
        }
        action={
          expandable && (
            <IconButton>
              {localOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )
        }
        onClick={() => setLocalOpen(!localOpen)}
        sx={{
          cursor: expandable ? 'pointer' : 'default',

          '&:hover .MuiCardHeader-action': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '50%',
          },
        }}
      />

      {expandable && (
        <Collapse in={localOpen}>
          <Divider />

          <CardContent>
            {'questions' in event.data && (
              <List sx={{ padding: 0 }}>
                {event.data.questions.map((question, index, arr) => {
                  return (
                    <Fragment key={question.id}>
                      {question.id === 'combiuitvoering' &&
                      question.disciplines ? (
                        question.disciplines.map((d, index, arr) => (
                          <Fragment key={d.discipline}>
                            <ListItem disableGutters sx={{ padding: 0 }}>
                              <ListItemIcon>
                                {d.answer ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </ListItemIcon>

                              <ListItemText>
                                {`${d.discipline} ${
                                  d.answer ? 'wel' : 'niet'
                                } mee in combi`}

                                {'text' in d && d.text && (
                                  <>
                                    <br />
                                    <Typography variant="caption">
                                      {d.text}
                                    </Typography>
                                  </>
                                )}
                              </ListItemText>
                            </ListItem>

                            {index !== arr.length - 1 && (
                              <Divider sx={{ pt: 1, mb: 1 }} />
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <ListItem
                          key={question.id}
                          disableGutters
                          sx={{ padding: 0 }}
                        >
                          <ListItemIcon>
                            {question.answer ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </ListItemIcon>

                          <ListItemText>
                            {questionIdMap[question.id]}
                          </ListItemText>
                        </ListItem>
                      )}

                      {index !== arr.length - 1 && (
                        <Divider sx={{ pt: 1, mb: 1 }} />
                      )}
                    </Fragment>
                  )
                })}
              </List>
            )}

            {'netbeheerderDisciplines' in event.data && (
              <Box sx={{ mb: 2, display: 'flex' }}>
                {event.data.netbeheerderDisciplines.map((nbDisc) => (
                  <DisciplineIcon
                    key={nbDisc.disciplineLabel}
                    disciplineCode={nbDisc.disciplineCode}
                    active={true}
                    toolTip={nbDisc.disciplineLabel}
                  />
                ))}
              </Box>
            )}

            {'isHoofdleidingAanleggen' in event.data && (
              <List sx={{ padding: 0 }}>
                <ListItem disableGutters sx={{ padding: 0 }}>
                  <ListItemIcon>
                    {event.data.isHoofdleidingAanleggen ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText>Hoofdleiding aanleggen</ListItemText>
                </ListItem>
              </List>
            )}

            {event.type === 'update_labels' && (
              <>
                {event.data.labels.length > 0 ? (
                  event.data.labels.map((label) => (
                    <Label key={label} text={label} sx={{ mt: 0 }} />
                  ))
                ) : (
                  <Typography paragraph m={0}>
                    Alle labels verwijderd
                  </Typography>
                )}
              </>
            )}

            {'doorlooptijdWeken' in event.data && (
              <>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Doorlooptijd
                </Typography>
                <Typography paragraph m={0}>
                  {event.data.doorlooptijdWeken} weken
                </Typography>
              </>
            )}

            {'reden' in event.data && (
              <>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Reden
                </Typography>
                <Typography paragraph m={0}>
                  {event.data.reden || 'Geen reden opgegeven.'}
                </Typography>
              </>
            )}

            {'disciplines' in event.data && (
              <List sx={{ padding: 0 }}>
                {event.data.disciplines &&
                  event.data.disciplines.map((d, index, arr) => (
                    <Fragment key={d.discipline}>
                      <ListItem sx={{ padding: 0 }} key={d.discipline}>
                        <ListItemIcon>
                          {d.isCombiUitvoering ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </ListItemIcon>

                        <ListItemText
                          primary={`${d.discipline} ${
                            d.isCombiUitvoering ? 'in' : 'uit'
                          } combi`}
                          secondary={'reden' in d ? d.reden : undefined}
                        />
                      </ListItem>

                      {index !== arr.length - 1 && (
                        <Divider sx={{ pt: 1, mb: 1 }} />
                      )}
                    </Fragment>
                  ))}
              </List>
            )}

            {event.type === 'hoofdleiding_details' && (
              <List sx={{ padding: 0 }}>
                {Object.entries(event.data).map(([key, value]) => {
                  if (updatableProps.includes(key)) {
                    let displayValue = value

                    if (key === 'aanvraagDatum' && !!displayValue) {
                      displayValue = formatDate(displayValue)
                    }

                    return (
                      <Typography paragraph sx={{ m: 0 }} key={key}>
                        <b>{getProjectLabel(key as any)}:</b>{' '}
                        {displayValue?.toString() || '-'}
                      </Typography>
                    )
                  }
                })}
              </List>
            )}

            {event.type === 'gebiedswissel' && (
              <>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Oud gebied
                </Typography>
                <Typography paragraph sx={{ m: 0 }}>
                  {gebiedscodeLabel(event.data.sourceGebiedscode)}
                </Typography>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Nieuw gebied
                </Typography>
                <Typography paragraph sx={{ m: 0 }}>
                  {gebiedscodeLabel(event.data.gebiedscode)}
                </Typography>
              </>
            )}

            {event.type === 'project_koppelen' && (
              <>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Project {event.data.addProject ? 'toegevoegd' : 'verwijderd'}
                </Typography>
                <Typography paragraph sx={{ m: 0 }}>
                  {event.data.addProject || event.data.deleteProject}
                </Typography>
                {event.data.linkedProjects && (
                  <>
                    <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                      Nieuwe lijst met projecten
                    </Typography>
                    <Typography paragraph sx={{ m: 0 }}>
                      {event.data.linkedProjects.join(', ')}
                    </Typography>
                  </>
                )}
              </>
            )}

            {event.type === 'technisch_gereed' &&
              event.data.disciplineVerzamelingen.map((dv) => (
                <Fragment key={dv.disciplineCode + dv.netbeheerderCode}>
                  <Typography paragraph sx={{ fontWeight: '700', mb: 0 }}>
                    {dv.netbeheerderLabel} ({dv.disciplineLabel})
                  </Typography>

                  {dv.technischGereed ? (
                    <Typography paragraph>
                      {`Technisch gereed gemeld op ${formatDate(
                        dv.technischGereed,
                      )}`}
                    </Typography>
                  ) : (
                    <Typography paragraph>Niet technisch gereed</Typography>
                  )}
                </Fragment>
              ))}
          </CardContent>
        </Collapse>
      )}
    </Card>
  )
}
