import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SaveIcon from '@mui/icons-material/Save'
import { Grow, IconButton, Tooltip } from '@mui/material'

import { addAlert } from '../../alerts/state'
import useLocalStorage from '../hooks/useLocalStorage'

const ICON_TIMER = 1000

interface Props<T> {
  name: string
  storable: T
  onClickLoad?: (params: T) => void
}

export default function LocalStorageButtons<T extends object>({
  name,
  storable,
  onClickLoad,
}: Props<T>) {
  const dispatch = useDispatch()
  const {
    available,
    checkAvailability,
    checkEquality,
    full,
    item,
    save,
    storableEqualsItem,
  } = useLocalStorage(name, storable)
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (full) {
      dispatch(
        addAlert({
          title: 'Filters opslaan niet mogelijk',
          message:
            'De lokale browser opslag is vol. Het opslaan van filters is niet mogelijk.',
        }),
      )
    }
  }, [dispatch, full])

  useEffect(() => {
    checkAvailability()
  }, [checkAvailability])

  useEffect(() => {
    checkEquality()
  }, [checkEquality, item, storable])

  useEffect(() => {
    if (!clicked) {
      return
    }

    /* istanbul ignore next */
    const timer = setTimeout(() => {
      setClicked(false)
    }, ICON_TIMER)

    return () => clearTimeout(timer)
  }, [clicked])

  return available ? (
    <>
      {onClickLoad && (
        <Tooltip
          title={
            storableEqualsItem
              ? 'Opgeslagen filters actief'
              : 'Gebruik opgeslagen filters'
          }
        >
          <span style={{ display: 'inline-block' }}>
            <IconButton
              disabled={storableEqualsItem}
              data-testid="loadlocalbutton"
              onClick={() => onClickLoad(item)}
            >
              <FilterAltIcon data-testid="filtericon" />
            </IconButton>
          </span>
        </Tooltip>
      )}

      <Tooltip
        title={storableEqualsItem ? 'Filters opgeslagen' : 'Filters opslaan'}
      >
        <span style={{ display: 'inline-block' }}>
          <IconButton
            disabled={storableEqualsItem}
            data-testid="savelocalbutton"
            onClick={() => {
              setClicked(true)
              save()
            }}
            sx={{ position: 'relative' }}
          >
            <Grow in={!clicked}>
              <SaveIcon data-testid="saveicon" />
            </Grow>

            <Grow in={clicked}>
              {full ? (
                <CloseIcon
                  color="error"
                  sx={{ position: 'absolute' }}
                  data-testid="closeicon"
                />
              ) : (
                <CheckIcon
                  color="success"
                  sx={{ position: 'absolute' }}
                  data-testid="checkicon"
                />
              )}
            </Grow>
          </IconButton>
        </span>
      </Tooltip>
    </>
  ) : null
}
