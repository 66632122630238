import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import PlanningConflict from '@components/PlanningConflict'
import { useProgress } from '@hooks/useProgress'
import { activiteittypes as hoofdleidingActiviteitTypes } from '@util/hoofdleiding-util'

import {
  ENTITY_PROJECT,
  createActiviteitForEntity,
  createActiviteitenForEntity,
  patchActiviteitForEntity,
} from './../../state'

const activiteitTypes = [
  'Afspraak',
  'Vergunning aanvragen',
  'Bodemonderzoek aanvragen',
  'Hoofdleiding aanleggen',
  'Extra werkvoorbereiding',
  'Vestiging zakelijk recht',
  'Wacht op klant',
  'Klantcontact',
  'Netcongestie Elektra',
]

const getActivityTypesByEntity = ({ type, projecttype } = {} as any) => {
  if (type === ENTITY_PROJECT && projecttype === 'Hoofdleiding')
    return hoofdleidingActiviteitTypes

  return activiteitTypes
}

const createOptionsForEdit = (assignees, assignedTo) => {
  const toReturn: any[] = []

  assignees.forEach((x) => {
    if (assignedTo.includes(x.label)) toReturn.push(x)
  })

  return toReturn
}

interface Props {
  data?: any
  handleClose?: () => void
}

export const CreateEditActiviteitDialog: FC<Props> = ({
  data = {},
  handleClose,
}) => {
  const dispatch = useDispatch()
  const progress = useProgress()

  const { assignees = [], entity, activiteit = {}, isEdit, entityProps } = data
  const [assignedTo, setAssignedTo] = useState<any[]>([])
  const [text, setText] = useState('')
  const [errors, setErrors] = useState({})
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [type, setType] = useState('Afspraak')
  const activiteitLabel = `activiteit${assignedTo.length === 1 ? '' : 'en'}`

  const setValues = useCallback(
    (activiteit) => {
      const { assignedTo, text, type, dueDate } = activiteit

      setType(type)
      setAssignedTo(createOptionsForEdit(assignees, assignedTo))
      setText(text)
      setDueDate(dueDate ? new Date(dueDate) : null)
    },
    [assignees],
  )

  useEffect(() => {
    if (isEdit) {
      setValues(activiteit)
    }
  }, [isEdit, activiteit, setValues])

  useEffect(() => {
    if (!isEdit) {
      setText('')
      setAssignedTo([])
      setDueDate(null)
      setErrors({})
    }
  }, [type, isEdit])

  const validate = () => {
    const textError = !text || text === ''
    const typeError = !type
    const assignedToError =
      type !== 'Afspraak' && type !== 'Klantcontact' && assignedTo.length === 0
    const dueDateError =
      !['Afspraak', 'Klantcontact', 'Netcongestie Elektra'].includes(type) &&
      !dueDate

    setErrors({
      textError,
      typeError,
      assignedToError,
      dueDateError,
    })

    return !textError && !typeError && !assignedToError && !dueDateError
  }

  const handleClickAnnuleren = () => {
    if (handleClose) {
      handleClose()
      progress.clear()
    }
  }

  const handleClickOpslaan = () => {
    if (!validate()) return

    if (isEdit) {
      /** Editing is only for a single activiteit. */
      dispatch(
        patchActiviteitForEntity({
          entity,
          activiteitInfo: {
            text,
            dueDate,
            activiteitId: activiteit._id,
          },
        }),
      )
    } else if (
      /** Types Afspraak and Klantcontact don't need assignees. */
      (type === 'Afspraak' || type === 'Klantcontact') &&
      assignedTo.length === 0
    ) {
      dispatch(
        createActiviteitForEntity({
          entity,
          activiteitInfo: {
            type,
            entity: {
              id: entity.id,
              type: entity.type,
              gebiedscode: entity.gebiedscode,
            },
            assignedTo,
            text,
            dueDate,
          },
        }),
      )
    } else {
      /** Creating activiteiten is done multiple at a time. */
      const activiteiten = assignedTo.map(({ label }) => ({
        type,
        entity: {
          id: entity.id,
          type: entity.type,
          gebiedscode: entity.gebiedscode,
        },
        assignedTo: [label],
        text,
        dueDate,
      }))

      dispatch(
        createActiviteitenForEntity({
          entity,
          activiteiten,
          progress: {
            ...progress,
            total: activiteiten.length,
            current: 1,
            error: false,
          },
        }),
      )
    }
  }

  return (
    <>
      <DialogTitle>
        {isEdit ? 'Bewerken activiteit' : 'Aanmaken activiteit'}
      </DialogTitle>

      <DialogContent>
        {type === 'Extra werkvoorbereiding' &&
          dueDate !== null &&
          entityProps && (
            <PlanningConflict
              geplandeUitvoeringsdatum={entityProps.geplandeUitvoeringsdatum}
              geplandeUitvoeringsdatumType={
                entityProps.geplandeUitvoeringsdatumType
              }
              opleverdatum={dueDate}
              combi={entityProps.combi}
            />
          )}

        <DialogContentText>
          {isEdit
            ? 'Bewerk een activiteit'
            : 'Kies een type activiteit om aan te maken en wijs hem optioneel toe aan een organisatie'}
        </DialogContentText>

        <form>
          <FormControl sx={{ mt: 1.5, width: '100%' }}>
            <Autocomplete
              options={getActivityTypesByEntity(entity)}
              onChange={(_, type: any) => setType(type)}
              value={type}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Type"
                  error={errors['typeError']}
                />
              )}
              disabled={isEdit}
            />
          </FormControl>

          <FormControl
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Autocomplete
              options={assignees}
              groupBy={(assignedTo) => assignedTo.group}
              onChange={(_, assignedTo) => setAssignedTo(assignedTo)}
              getOptionLabel={(assignedTo) => assignedTo.label}
              value={assignedTo}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Toegewezen aan"
                  error={errors['assignedToError']}
                  style={{ marginTop: 3 }}
                />
              )}
              multiple
              sx={{ width: '50%', pr: 1 }}
              disabled={isEdit || type === 'Klantcontact'}
            />

            <DatePicker
              format="dd-MM-yyyy"
              label="Opleverdatum"
              disablePast
              value={dueDate}
              onChange={setDueDate}
              displayWeekNumber
              disabled={type === 'Klantcontact'}
              slotProps={{
                field: { clearable: true },
                textField: {
                  variant: 'standard',
                  sx: { width: '50%' },
                  error: errors['dueDateError'],
                },
              }}
            />
          </FormControl>

          <FormControl sx={{ mt: 3, width: '100%' }}>
            <TextField
              value={text}
              multiline
              rows={6}
              label={`Opmerking (${text.length}/500)`}
              onChange={(e) => setText(e.target.value)}
              required
              error={errors['textError']}
              inputProps={{ maxLength: 500 }}
            />
          </FormControl>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickAnnuleren} color="primary" autoFocus>
          Annuleren
        </Button>

        {isEdit && (
          <Button onClick={handleClickOpslaan} color="primary">
            Bewerken
          </Button>
        )}

        {!isEdit && progress.error && (
          <Button onClick={handleClickOpslaan} color="primary">
            Opnieuw proberen (vanaf {progress.current}e activiteit)
          </Button>
        )}

        {!isEdit && !progress.error && progress.total > progress.current && (
          <>
            <Button disabled>
              Aanmaken {activiteitLabel}... ({progress.current}/{progress.total}
              )
            </Button>
          </>
        )}

        {!isEdit && progress.current === 0 && (
          <Button onClick={handleClickOpslaan} color="primary">
            Aanmaken
            {assignedTo.length > 0 &&
              ` (${assignedTo.length} ${activiteitLabel})`}
          </Button>
        )}
      </DialogActions>
    </>
  )
}
