import React, { FC, useState } from 'react'

import { Drawer, Grid, TableCell, TableRow } from '@mui/material'

import { ProductIcon } from '@components/ProductIcon'
import { disciplines, getProductsOrDefault } from '@util/discipline'
import { createBadge } from '@util/kpi-util'

import type {
  AansluitObject as AansluitObjectType,
  Project,
} from '../../project/types'

import { Disciplines } from './Disciplines'

const objectIdFilter = (id) => (object) => object.objectID === id

export interface Props {
  project: Project
  row: AansluitObjectType
}

export const AansluitObject: FC<Props> = ({ project, row }) => {
  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const [drawer, setDrawer] = useState(false)
  const getProducts = getProductsOrDefault(row.produkten)

  const toggleDrawer = (open) => (product?: any) => () => {
    setSelectedProduct(product || {})
    setDrawer(open)
  }

  return (
    <TableRow key={row.objectID} tabIndex={-1}>
      <TableCell>
        <Grid container direction="row">
          {disciplines
            .reduce(
              (curr, disciplineCode) =>
                curr.concat(getProducts(disciplineCode)),
              [],
            )
            .map((product: any) => (
              // TODO Add kpi badges
              <ProductIcon
                key={`${row.objectID}${product.disciplineCode}${product.netbeheerderCode}`}
                badge={createBadge(product.kpi)}
                product={product}
                onClick={toggleDrawer(true)(product)}
              />
            ))}
        </Grid>
      </TableCell>
      <TableCell>{row.objectID}</TableCell>
      <TableCell>{row.objectTypeLabel}</TableCell>
      <TableCell>{row.adres}</TableCell>
      <TableCell>{row.statusText}</TableCell>
      <TableCell />
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)()}>
        <Disciplines
          project={project}
          product={selectedProduct}
          objectFilter={objectIdFilter(row.objectID)}
          onClose={toggleDrawer(false)()}
        />
      </Drawer>
    </TableRow>
  )
}
