import React, { useState } from 'react'

import { Badge, Grid, IconButton, Popover, PopoverOrigin } from '@mui/material'

import { ProductIcon } from '@components/ProductIcon'
import { disciplines, getProductsOrDefault } from '@util/discipline'
import { createBadge } from '@util/kpi-util'

import type { Project } from '../types'

interface Props {
  project: Project
  placement?: PopoverOrigin['horizontal']
}

export default function Kpi({ project, placement = 'left' }: Props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const getProducts = getProductsOrDefault(project.disciplineVerzamelingen)
  const badge = createBadge(project.kpi)
  const badgeColor = badge && badge.color === 'red' ? badge.color : 'darkorange'
  const badgeCount = (badge && badge.count) || 0
  const id = project.aanvraagID

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)

  return (
    <>
      <IconButton
        sx={{ width: '40px', height: '40px' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: badgeColor,
              color: 'white',
            },
          }}
          badgeContent={badgeCount}
          overlap="circular"
        >
          <div></div>
        </Badge>
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          '& .MuiPopover-paper': {
            p: 1,
            backgroundColor: 'rgba(97,97,97,.9)',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: placement,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: placement,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container direction="row">
          {disciplines
            .reduce(
              (curr, disciplineCode) =>
                curr.concat(getProducts(disciplineCode)),
              [],
            )
            .map((product: any) => (
              <ProductIcon
                key={`kpi-popover-${id}${product.disciplineCode}${product.netbeheerderCode}`}
                product={product}
                badge={createBadge(product.kpi)}
              />
            ))}
        </Grid>
      </Popover>
    </>
  )
}
